import styled from '@emotion/styled';

const LineEffectStyled = styled.div`
position: relative;
display: inline-block;
  
  &::after, &::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background-color: gray;
    top: 55%;
    animation: animateLine 1s;
  }
  &::after {
    left: -1px;
  }
  &::before {
    right: -1px;
  }
`;

export default LineEffectStyled;
