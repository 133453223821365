import React, { useContext, useEffect } from 'react';
import { AlertaCirculo } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/pictures';
import { ButtonLink } from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import Typography from '@womcorp/wom-miwom-ui-kit/dist/components/Typography';
import Panel from '@womcorp/wom-miwom-ui-kit/dist/components/Panel';
import PanelStyled from '../styles/PanelStyled';
import { LoginContext } from '../context/LoginProvider';
import GTMError from '../helpers-gtm/GTMError';
import { getEncodedUserID } from '../../../helpers/userID';

const LimitReachedPanel = () => {
  const { forbidden, kind, setForbidden, value } = useContext(LoginContext);
  const onClose = () => setForbidden(false);

  useEffect(() => {
    if (forbidden) GTMError.tagManagerVirtualPageLimitReachedMobile(getEncodedUserID(kind, value));
  }, [forbidden]);

  return (
    <Panel open={forbidden} title="No es posible continuar" onClose={onClose} maxWidth={360}>
      <PanelStyled>
        <AlertaCirculo />
        <Typography type="Subtitulo20" weight="bold">Máximo de intentos superados</Typography>
        <Typography type="Body">
          Has superado los intentos posibles para ingresar el código de seguridad.<br/>
          Por favor <b>espera 30 minutos</b> para volver a intentarlo
        </Typography>
        <ButtonLink colorName="bubblegum" onClick={onClose}>Cerrar</ButtonLink>
      </PanelStyled>
    </Panel>
  );
}

export default LimitReachedPanel;
