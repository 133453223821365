import { UniverseType, KindType } from '../@types';
import TagManager from '../../../common/TagManager';

const tagManagerVirtualPage = (universe: UniverseType, kind?: KindType, userID?: string) => {
  if (universe === 'phone'){
    if (kind === 'phone'){
      TagManager.VirtualPage({
        page: '/sso/movil/paso2',
        pagename: 'SSO_LOGIN_MOVIL_PASO_2',
        userID,
      })
    } else if (kind === 'rut'){
      TagManager.VirtualPage({
        page: '/sso/movil/paso2',
        pagename: 'SSO_LOGIN_MOVIL_PASO_2_RUT',
        userID,
      });
    }
  } else if (universe === 'bam'){
    TagManager.VirtualPage({
      page: '/sso/bam/paso2',
      pagename: 'SSO_LOGIN_BAM_PASO_2',
    });
  } else if (universe === 'fiber'){
    TagManager.VirtualPage({
      page: '/sso/fibra/paso2',
      pagename: 'SSO_LOGIN_FIBRA_PASO_2',
    });
  } else if (universe === 'tv'){
    TagManager.VirtualPage({
      page: '/sso/womtv/paso2',
      pagename: 'SSO_LOGIN_WOMTV_PASO_2',
    });
  }
};

type TypeTagMobileEvent =
  'Iniciar sesión' |
  'Reenviar código' |
  'Ingresar con otro número' |
  'Ingresar con otro RUT';

const tagMobileEvent = (tag: TypeTagMobileEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria': 'Login Movil',
    'evento-interactivo-accion': 'OTP',
    'evento-interactivo-etiqueta': tag,
  })
};

type TypeTagBamEvent =
  'Iniciar sesión' | 'Reenviar código' | 'Ingresar con otro RUT';

const tagBamEvent = (tag: TypeTagBamEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria': 'Login BAM',
    'evento-interactivo-accion': 'OTP',
    'evento-interactivo-etiqueta': tag,
  })
};

type TypeTagFiberEvent =
  'Iniciar sesión' | 'Reenviar código' | 'Ingresar con otro RUT';

const tagFiberEvent = (tag: TypeTagFiberEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria': 'Login Fibra',
    'evento-interactivo-accion': 'OTP',
    'evento-interactivo-etiqueta': tag,
  })
};

type TypeTagEventWomTV =
  'Iniciar sesión' | 'Reenviar código' | 'Usar otro e-mail';

const tagWomTVEvent = (tag: TypeTagEventWomTV) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria': 'Login WOMTV',
    'evento-interactivo-accion': 'OTP',
    'evento-interactivo-etiqueta': tag,
  })
};

export default {
  tagManagerVirtualPage,
  tagMobileEvent,
  tagBamEvent,
  tagFiberEvent,
  tagWomTVEvent,
};
