import React, { FormEvent, useContext, useEffect, useState } from "react";
import FormLayoutStyled from "./styles/FormLayoutStyled";
import { EmailInput } from "@womcorp/wom-miwom-ui-kit/dist/components/Input";
import { ButtonLink } from "@womcorp/wom-miwom-ui-kit/dist/components/Button";
import Button from "@womcorp/wom-miwom-ui-kit/dist/components/Button";
import Typography from "@womcorp/wom-miwom-ui-kit/dist/components/Typography/Typography";
import useGetOtp from "./hooks/useGetOtp";
import { LoginContext } from "./context/LoginProvider";
import { LoaderBlock } from "@womcorp/wom-miwom-ui-kit/dist/components/Loader";
import { GridLayout } from "@womcorp/wom-miwom-ui-kit/dist/components/Layout";
import { OttIcon } from "@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/forms";
import TvPanel from "./panel/TvPanel";
import LineEffectStyled from "./styles/Lines-effect";
import GTMStepOne from './helpers-gtm/GTMStepOne';

const ByTv = () => {
  const {
    value,
    setValue,
    setKind,
    serviceError,
  } = useContext(LoginContext);
  const [isValid, setIsValid] = useState(true);
  const [getOtp, loading] = useGetOtp();
  const [openPanel, setOpenPanel] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    getOtp();
  };

  useEffect(() => {
    setKind("email");
  }, []);

  useEffect(() => {
    if (serviceError === 400) setIsValid(false);
  }, [serviceError])

  if (loading) return <LoaderBlock />;

  return (
    <>
      <FormLayoutStyled onSubmit={handleSubmit}>
        <EmailInput
          value={value}
          error={!isValid}
          errorText='El Email ingresado no es válido'
          onChange={setValue}
          setValid={setIsValid}
          label='Email'
        />
        <Button
          disabled={!isValid || value === ""}
          onClick={() => {
            GTMStepOne.tagWomTVEvent('Solicitar Código')
            getOtp();
          }}
          children='Solicitar Código'
        />
        <ButtonLink
          onClick={() => {
            GTMStepOne.tagWomTVEvent('Olvidé mi Email')
            setOpenPanel(true);
          }}
          children='Olvidé mi Email'
        />
        <LineEffectStyled>
          <Typography type='Caption'>
            ¿Aún no eres cliente de WOM TV?
          </Typography>
        </LineEffectStyled>
        <Button
          variant='outlined'
          color='corporate'
          external={{
            href: `${process.env.TV_CHANNEL_URL}/inscripcion/`,
            target: "_blank",
          }}
          onClick={() => GTMStepOne.tagWomTVEvent('Comenzar Registro')}
        >
          <GridLayout gap={8} columns='auto 1fr'>
            <OttIcon />
            <div>COMENZAR REGISTRO</div>
          </GridLayout>
        </Button>
      </FormLayoutStyled>
      <TvPanel open={openPanel} setOpen={setOpenPanel} />
    </>
  );
};

export default ByTv;
