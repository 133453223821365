import React, { useContext, useMemo } from 'react';
import AppContext from '../AppContext';

export type TypeUseAvailableChannel = () => ({
  mobile?: boolean,
  bam?: boolean,
  fiber?: boolean,
  tv?: boolean,
}) | null;

const UseAvailableChannel: TypeUseAvailableChannel = () => {
  const { availableChannels } = useContext(AppContext);

  return availableChannels;
}

export default UseAvailableChannel;
