import { CleanRut } from "@womcorp/wom-miwom-ui-kit/dist/components/Input/@helpers/Parser";
import { encodeBase64 } from "./Base64";

export const getEncodedUserID = (kind: string, value: string) =>
  encodeBase64(
    {
      email: value,
      rut: CleanRut(value),
      phone: `56${value.replace(/ /g, "")}`,
    }[kind] || ""
  );
