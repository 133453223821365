import { useContext, useEffect, useState } from 'react';
import { LoginContext } from '../context/LoginProvider';
import {
  getOTPByNumber,
  getOTPByRut,
  getOTPByEmail,
} from '../../../services/OTPServices';
import {
  CleanRut
} from '@womcorp/wom-miwom-ui-kit/dist/components/Input/@helpers/Parser';
import GTMError from '../helpers-gtm/GTMError';


const useGetOtp: () => [(() => void), boolean, number] = () => {
  const {
    value,
    universe,
    kind,
    setOtpAvailable,
    serviceError,
    setServiceError,
    setRequest,
    setForbidden,
    setOtp,
    setReceiver,
  } = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);

  // Do not refactor: useful to respect the React lifecycle component
  useEffect(() => {
    if (status === 200)
      setOtpAvailable(true);
    else if (status === 403)
      setForbidden(true);
    else if (status >= 400) {
      setServiceError(status)
    }
  }, [status]);

  useEffect(() => {
    if (serviceError === 400)
      GTMError.tagManagerVirtualPageUniverseValueError(universe);
  }, [serviceError])

  const handleGetOtp = () => {
    // clean up
    setLoading(true);
    setServiceError(0);
    setRequest(null);
    setForbidden(false);
    setOtp('');
    setStatus(0);

    const FetchRequest = ({
      phone: () => getOTPByNumber(value.replace(/ /g, '')),
      rut: () => getOTPByRut(CleanRut(value)),
      email: () => getOTPByEmail(value),
    }[kind]);

    FetchRequest()
      .then(response => {
        const { status } = response;
        setStatus(status);
        if (status === 200) setReceiver(response.data.receiver)
        else throw new Error();
      })
      .catch(() => {
        setRequest(() => handleGetOtp)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return [handleGetOtp, loading, status];
}

export default useGetOtp;
