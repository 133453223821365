import React, { FormEvent, useContext, useEffect, useState } from 'react';
import FormLayoutStyled from './styles/FormLayoutStyled';
import { RutInput } from '@womcorp/wom-miwom-ui-kit/dist/components/Input';
import Button, { ButtonLink } from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import { LoaderBlock } from '@womcorp/wom-miwom-ui-kit/dist/components/Loader';
import { LoginContext } from './context/LoginProvider';
import useGetOtp from './hooks/useGetOtp';
import GTMStepOne from './helpers-gtm/GTMStepOne';
import PhonePanel from './panel/PhonePanel';

const ByPhoneRut = () => {
  const { value, setValue, setKind, serviceError } = useContext(LoginContext);
  const [invalid, setInvalid] = useState(false);
  const isDisabled = value.length < 11;
  const [getOtp, loading] = useGetOtp();
  const [openPanel, setOpenPanel] = useState(false);

  const handleClickTogglePhone = () => {
    GTMStepOne.tagMobileRutEvent('Ingresar con Teléfono');
    setKind('phone');
  }

  const handleGetOtp = () => {
    GTMStepOne.tagMobileRutEvent('Solicitar Código');
    getOtp();
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleGetOtp();
  }

  useEffect(() => {
    setKind('rut');
  }, []);

  useEffect(() => {
    if (serviceError === 400 || serviceError === 404) setOpenPanel(true);
  }, [serviceError])

  if (loading) return <LoaderBlock />

  return (
    <>
      <FormLayoutStyled onSubmit={handleSubmit}>
        <RutInput
          value={value}
          error={invalid}
          onChange={setValue}
          setInvalid={setInvalid}
          label="Rut"
        />
        <Button
          disabled={isDisabled || invalid}
          onClick={handleGetOtp}
          children="Solicitar Código"
        />
        <ButtonLink onClick={handleClickTogglePhone}>
          Ingresar con Télefono
        </ButtonLink>
      </FormLayoutStyled>
      <PhonePanel open={openPanel} setOpen={setOpenPanel}/>
    </>
  );
};

export default ByPhoneRut;
