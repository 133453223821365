import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import { colors } from '@womcorp/wom-miwom-ui-kit/dist/@theme/colors';

const SSOSelectStyled = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

const ItemStyled = styled(MenuItem)`
  min-height: 40px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  svg {
    height: 22px;
  }
  &:hover {
    background-color: ${colors.Neutros5_60};
  }
  &&.Mui-selected {
    background-color: ${colors.Neutros5};
  }
`;


export {
  SSOSelectStyled as default,
  ItemStyled,
}
