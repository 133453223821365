import styled from '@emotion/styled';

const PanelStyled = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > svg {
    margin: 22px auto;
  }
  & > h5 {
    margin-bottom: 16px;
  }
  
  & > button:nth-of-type(1) {
    margin-top: 32px;
    margin-bottom: 8px;
  }
`;


export default PanelStyled;
