////////////
// @TYPES //
////////////
type TypeParamsVirtualPage = {
  page: string,
  pagename: string,
  userID?: string,
};

type TypeVirtualPageTagManager =
  TypeParamsVirtualPage & { event: 'pagina-virtual' }

type TypeVirtualPage = (params: TypeParamsVirtualPage) => void;

type TypeParamsInteractiveEvent = {
  'evento-interactivo-categoria': string,
  'evento-interactivo-accion': string,
  'evento-interactivo-etiqueta': string,
};

type TypeInteractiveEventTagManager =
  TypeParamsInteractiveEvent & { event: 'evento-interactivo' }

type TypeInteractiveEvent = (params: TypeParamsInteractiveEvent) => void;

type TypePushToDataLayer =
  (params: TypeVirtualPageTagManager | TypeInteractiveEventTagManager) => void;

/////////////
// METHODS //
/////////////
declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const pushToDataLayer: TypePushToDataLayer = (params) => {
  if (typeof window !== 'undefined' && 'dataLayer' in window){
    window.dataLayer.push(params);
  }
}

const VirtualPage: TypeVirtualPage = (params) => {
  const vpParams: TypeVirtualPageTagManager = { ...params, event: 'pagina-virtual' };
  pushToDataLayer(vpParams);
};

const InteractiveEvent: TypeInteractiveEvent = (params) => {
  const ieParams: TypeInteractiveEventTagManager = { ...params, event: 'evento-interactivo' };
  pushToDataLayer(ieParams);
};

export default { VirtualPage, InteractiveEvent };
