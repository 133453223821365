import React, { useContext, useEffect } from 'react';
import { BamPrepago } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/pictures';
import Button, { ButtonLink } from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import Typography from '@womcorp/wom-miwom-ui-kit/dist/components/Typography';
import Panel from '@womcorp/wom-miwom-ui-kit/dist/components/Panel';
import PanelStyled from '../styles/PanelStyled';
import { PanelType } from '../@types';
import { LoginContext } from '../context/LoginProvider';
import GTMError from '../helpers-gtm/GTMError';

const BamPanel: PanelType = ({ open, setOpen}) => {
  const { setIsLogged } = useContext(LoginContext)
  const onClose = () => {
    GTMError.tagManagerEventBamPrepaidPanel('Cancelar')
    setOpen(false);
  }

  useEffect(() => {
    if (open) GTMError.tagManagerVirualPageBamPrepaidPanel();
  }, [open])

  const handleClickIngresar = () => {
    GTMError.tagManagerEventBamPrepaidPanel('Ingresar');
    setIsLogged(true);
  }

  return (
    <Panel open={open} title="Ingresar" onClose={onClose} maxWidth={360}>
      <PanelStyled>
        <BamPrepago />
        <Typography type="Subtitulo20" weight="bold">Ingresar con una BAM prepago</Typography>
        <Typography type="Body">
          Si tienes una BAM WOM Prepago, sólo <b>ingresa conectándote a MIWOM
          directamente desde tu Router</b>
        </Typography>
        <Button onClick={handleClickIngresar}>Ingresar</Button>
        <ButtonLink colorName="bubblegum" onClick={onClose}>Cancelar</ButtonLink>
      </PanelStyled>
    </Panel>
  );
}

export default BamPanel;
