import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { LoginContext } from './context/LoginProvider';
import { SelectionWrapperStyled } from './styles/LoginStyled';
import { channelRedirect } from '../../common/Utils';

import SSOSelect, { SSOEventType } from '../SSOSelect/SSOSelect';
import ByPhone from './ByPhone';
import ByBam from './ByBam';
import ByFiber from './ByFiber';
import ByTv from './ByTv';
import { LoginPageUniverseType, UniverseType } from './@types';
import LoginOTP from './LoginOTP';
import GenericErrorPanel from './panel/GenericErrorPanel';
import LimitReachedPanel from './panel/LimitReachedPanel';
import { LoaderBlock } from '@womcorp/wom-miwom-ui-kit/dist/components/Loader';
import UniverseSelector from '../../helpers/UniverseSelector';
import GTMStepOne from './helpers-gtm/GTMStepOne';
import useAvailableChannel from '../App/hooks/useAvailableChannel';
import AppContext from '../App/AppContext';


const Login: LoginPageUniverseType = ({ universe: propUniverse }) => {
  const {
    universe,
    setUniverse,
    otpAvailable,
    isLogged,
    cleanUp,
    kind,
  } = useContext(LoginContext);
  const availableChannels = useAvailableChannel();
  const { urlRedirect } = useContext(AppContext);

  useLayoutEffect(() => {
    if (propUniverse !== undefined)
      setUniverse(propUniverse);
  }, []);

  useEffect(() => {
    cleanUp();
    GTMStepOne.tagManagerVirtualPage(universe, kind);
  }, [universe])

  const universeHandleChange: SSOEventType = (e) => {
    const newUniverse = e.target.value as UniverseType;
    UniverseSelector(newUniverse);
  }

  if (isLogged) {
    channelRedirect({ universe, urlRedirect });

    return (
      <SelectionWrapperStyled>
        <LoaderBlock />
      </SelectionWrapperStyled>
    )
  }
  
  return (
    <>
      <SelectionWrapperStyled>
        <div>Estás ingresando a:</div>
        {
          !availableChannels ? <LoaderBlock /> : (
            <>
              <div>
                <SSOSelect
                  value={universe}
                  onChange={universeHandleChange}
                  availableChannels={availableChannels}
                />
              </div>
              {
                otpAvailable && <LoginOTP /> ||
                universe === 'phone' && availableChannels.mobile && <ByPhone /> ||
                universe === 'bam' && availableChannels.bam && <ByBam /> ||
                universe === 'fiber' && availableChannels.fiber && <ByFiber /> ||
                universe === 'tv' && availableChannels.tv && <ByTv />
              }
            </>
          )
        }
      </SelectionWrapperStyled>
      <GenericErrorPanel />
      <LimitReachedPanel />
    </>
  );
};

export default Login;
