import React, { FormEvent, useContext, useEffect } from 'react';
import FormLayoutStyled from './styles/FormLayoutStyled';
import { PhoneInput } from '@womcorp/wom-miwom-ui-kit/dist/components/Input';
import Button, { ButtonLink } from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import { LoaderBlock } from '@womcorp/wom-miwom-ui-kit/dist/components/Loader';
import { LoginContext } from './context/LoginProvider';
import useGetOtp from './hooks/useGetOtp';
import GTMStepOne from './helpers-gtm/GTMStepOne';

const ByPhoneNumber = () => {
  const { value, setValue, setKind } = useContext(LoginContext);
  const isDisabled = value.replace(/ /g, '').length !== 9;
  const [getOtp, loading] = useGetOtp();

  const handleClickToggleRut = () => {
    GTMStepOne.tagMobileEvent('Ingresar con RUT');
    setKind('rut');
  }

  const handleGetOtp = () => {
    GTMStepOne.tagMobileEvent('Solicitar Código');
    getOtp();
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleGetOtp();
  }

  useEffect(() => {
    setKind('phone');
  }, []);

  if (loading) return <LoaderBlock />

  return (
    <FormLayoutStyled onSubmit={handleSubmit}>
      <PhoneInput
        value={value}
        error={isDisabled && value !== ''}
        onChange={(e) => setValue(e.target.value)}
        label="Teléfono"
        type="tel"
      />
      <Button
        disabled={isDisabled}
        onClick={handleGetOtp}
        children="Solicitar Código"
      />
      <ButtonLink onClick={handleClickToggleRut}>
        Ingresar con RUT
      </ButtonLink>
    </FormLayoutStyled >
  );
};

export default ByPhoneNumber;
