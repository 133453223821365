import TagManager from '../../../common/TagManager';
import { UniverseType } from '../@types';

const tagManagerVirtualPageOtpError = (
  universe: UniverseType,
  userID: string
) => {
  if (universe === 'phone') {
    TagManager.VirtualPage({
      page: '/sso/movil/errorotp',
      pagename: 'SSO_MOVIL_OTP_INCORRECTO',
      userID,
    });
  } else if (universe === 'bam') {
    TagManager.VirtualPage({
      page: '/sso/bam/errorotp',
      pagename: 'SSO_BAM_OTP_INCORRECTO',
    });
  } else if (universe === 'fiber') {
    TagManager.VirtualPage({
      page: '/sso/fibra/errorotp',
      pagename: 'SSO_FIBRA_OTP_INCORRECTO',
    });
  } else if (universe === 'tv') {
    TagManager.VirtualPage({
      page: '/sso/womtv/errorotp',
      pagename: 'SSO_WOMTV_OTP_INCORRECTO',
    });
  }
};

const tagManagerVirtualPageUniverseValueError = (universe: UniverseType) => {
  if (universe === 'phone') {
    TagManager.VirtualPage({
      page: '/sso/movil/errorlinea',
      pagename: 'SSO_MOVIL_LINEA_INCORRECTA',
    });
  } else if (universe === 'fiber') {
    TagManager.VirtualPage({
      page: '/sso/fibra/rutincorrecto',
      pagename: 'SSO_FIBRA_RUT_INCORRECTO',
    });
  } else if (universe === 'tv') {
    TagManager.VirtualPage({
      page: '/sso/womtv/emailincorrecto',
      pagename: 'SSO_WOMTV_EMAIL_INCORRECTO',
    });
  }
}

const tagManagerVirtualPageTvPanelError = () => {
  TagManager.VirtualPage({
    page: '/sso/womtv/ayuda',
    pagename: 'SSO_LOGIN_WOMTV_OLVIDE_MAIL',
  });
}

const tagManagerVirtualPageLimitReachedMobile = (userID: string) => {
  TagManager.VirtualPage({
    page: '/sso/movil/errorintentos',
    pagename: 'SSO_MOVIL_AGOTA_INTENTOS',
    userID,
  });
}

const tagManagerVirualPageBamPrepaidPanel = () => {
  TagManager.VirtualPage({
    page: '/sso/bam/prepago',
    pagename: 'SSO_LOGIN_BAM_PREPAGO',
  });
}

type TypeTagManagerEventBamPrepaidPanel = 'Ingresar' | 'Cancelar';

const tagManagerEventBamPrepaidPanel =
  (tag: TypeTagManagerEventBamPrepaidPanel) => {
    TagManager.InteractiveEvent({
      'evento-interactivo-categoria': 'Login BAM Prepago',
      'evento-interactivo-accion': 'Ingreso',
      'evento-interactivo-etiqueta': tag,
    });
  }

const tagManagerVirualPageFiberPanel = () => {
  TagManager.VirtualPage({
    page: '/sso/fibra/rutnoasociado',
    pagename: 'SSO_FIBRA_RUT_SIN_CORREO',
  });
}

export default {
  tagManagerVirtualPageOtpError,
  tagManagerVirtualPageUniverseValueError,
  tagManagerVirtualPageTvPanelError,
  tagManagerVirtualPageLimitReachedMobile,
  tagManagerVirualPageBamPrepaidPanel,
  tagManagerEventBamPrepaidPanel,
  tagManagerVirualPageFiberPanel,
}
