import React, { useContext } from 'react';
import ByPhoneNumber from './ByPhoneNumber';
import ByPhoneRut from './ByPhoneRut';
import { LoginContext } from './context/LoginProvider';


const ByPhone = () => {
  const { kind } = useContext(LoginContext);

  if (kind === 'phone') return <ByPhoneNumber />;
  if (kind === 'rut') return <ByPhoneRut />
  return <></>;
};

export default ByPhone;
