import React, { useContext, useEffect } from 'react';
import { LoaderBlock } from '@womcorp/wom-miwom-ui-kit/dist/components/Loader';
import { FlexLayout } from '@womcorp/wom-miwom-ui-kit/dist/components/Layout';
import { ButtonLink } from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import OTPComponent from '@womcorp/wom-miwom-ui-kit/dist/components/OTP';
import { LoginContext } from './context/LoginProvider';
import useSetOtp from './hooks/useSetOtp';
import useGetOtp from './hooks/useGetOtp';
import GTMStepTwo from './helpers-gtm/GTMStepTwo';
import { getEncodedUserID } from '../../helpers/userID';

const LoginOTP = () => {
  const { value, otp, receiver, cleanUp, kind, universe } = useContext(LoginContext);
  const [setOtpCallback, loading, error] = useSetOtp();
  const [sendAnotherOtp, loadingAnotherRequest] = useGetOtp();
  const number = value.split(' ');

  const changeLoginMethod = () => {
    if (universe === 'phone') {
      const byKindArgument =
        kind === 'phone' ? 'Ingresar con otro número' : 'Ingresar con otro RUT';
        GTMStepTwo.tagMobileEvent(byKindArgument);
    } else if (universe === 'bam')
      GTMStepTwo.tagBamEvent('Ingresar con otro RUT');
    else if (universe === 'fiber')
      GTMStepTwo.tagFiberEvent('Ingresar con otro RUT');
    else if (universe === 'tv')
      GTMStepTwo.tagWomTVEvent('Usar otro e-mail');
    cleanUp();
  };

  const handleSendAnotherOtp = () => {
    if (universe === 'phone') GTMStepTwo.tagMobileEvent('Reenviar código');
    else if (universe === 'bam') GTMStepTwo.tagBamEvent('Reenviar código');
    else if (universe === 'fiber') GTMStepTwo.tagFiberEvent('Reenviar código');
    else if (universe === 'tv') GTMStepTwo.tagWomTVEvent('Reenviar código');
    sendAnotherOtp();
  }

  useEffect(() => {
    GTMStepTwo.tagManagerVirtualPage(universe, kind, getEncodedUserID(kind, value));
  }, []);

  const getHeader = () => {
    return kind === 'phone'
      ? (
        <>
          Enviamos el código de acceso a <b>+56&nbsp;{number[0]}&nbsp;{number[1]}</b>
        </>
      )
      : (
        <>
          Enviamos el código de acceso al Email <b>{receiver}</b>
        </>
      );
  };

  if (loading || loadingAnotherRequest) return <LoaderBlock />

  return (
    <>
      <OTPComponent
        invalidOTP={otp}
        header={getHeader()}
        onSubmitCallback={setOtpCallback}
        sendAnotherCode={handleSendAnotherOtp}
        type='tel'
        error={error}
      />
      <FlexLayout margin="32px auto auto auto">
        {
          kind === 'phone'
            ? <ButtonLink onClick={changeLoginMethod}>Ingresar con otro número</ButtonLink>
            : <ButtonLink onClick={changeLoginMethod}>¿Este no es tu mail?</ButtonLink>
        }
      </FlexLayout>
    </>
  )
};

export default LoginOTP;
