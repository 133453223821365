import React, { useContext, useEffect } from 'react';
import { AlertaCirculo } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/pictures';
import { WhatsappIcon } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/forms';
import Button from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import {
  FlexLayout,
  GridLayout,
} from '@womcorp/wom-miwom-ui-kit/dist/components/Layout';
import Typography from '@womcorp/wom-miwom-ui-kit/dist/components/Typography';
import Panel from '@womcorp/wom-miwom-ui-kit/dist/components/Panel';
import PanelStyled from '../styles/PanelStyled';
import { PanelType } from '../@types';
import { LoginContext } from '../context/LoginProvider';
import TagManager from '../../../common/TagManager';
import { getEncodedUserID } from '../../../helpers/userID';

const PhonePanel: PanelType = ({ open, setOpen }) => {
  const { value, kind } = useContext(LoginContext);
  const onClose = () => setOpen(false);

  const markTagManagerError = () =>
    open &&
    TagManager.VirtualPage({
      page: '/sso/error-rut',
      pagename: 'SSO_MOVIL_SINRUT',
      userID: getEncodedUserID(kind, value),
    });

  useEffect(() => {
    markTagManagerError();
  }, []);

  return (
    <Panel
      open={open}
      title="No es posible continuar"
      onClose={onClose}
      maxWidth={360}
    >
      <PanelStyled>
        <AlertaCirculo />
        <Typography type="Subtitulo20" weight="bold">
          RUT no asociado
        </Typography>
        <Typography type="Body">
          El RUT <b>{value}</b> no tiene correo asociado para que te puedas
          registrar, lo lamentamos
        </Typography>
        <br />
        <Typography type="Body">Para regularizar esta situación:</Typography>
        <FlexLayout margin="32px 0 8px 0">
          <Button
            external={{
              href: 'tel:+56223377600',
              target: '_blank',
            }}
            variant="outlined"
            color="corporate"
          >
            Llámanos al 22 3377 600
          </Button>
        </FlexLayout>
        <Button
          external={{
            href: 'https://wa.me/56935223070',
            target: '_blank',
          }}
          variant="outlined"
          color="corporate"
        >
          <GridLayout gap={10} columns="auto 1fr">
            <WhatsappIcon />
            <div>Whatsapp ejecutivo</div>
          </GridLayout>
        </Button>
      </PanelStyled>
    </Panel>
  );
};

export default PhonePanel;
