import React, { createContext, useState } from 'react';
import { ChildrenType, LoginContextType, UniverseType, KindType } from '../@types';

const LoginContext = createContext<LoginContextType>({} as LoginContextType);

const LoginProvider = (
  { children, initialUniverse }: { children: ChildrenType, initialUniverse: UniverseType}
) => {
  // TODO: create a reducer control function
  const [universe, setUniverse] = useState<UniverseType>(initialUniverse);
  const [value, setValue] = useState('');
  const [otpAvailable, setOtpAvailable] = useState(false);
  const [otp, setOtp] = useState('');
  const [serviceError, setServiceError] = useState(0);
  const [request, setRequest] = useState<null | (() => void)>(null);
  const [forbidden, setForbidden] = useState(false);
  const [kind, setKind] = useState<KindType>('phone');
  const [isLogged, setIsLogged] = useState(false);
  const [receiver, setReceiver] = useState('');

  const cleanUp = () => {
    setValue('');
    setReceiver('');
    setRequest(null);
    setOtpAvailable(false);
    setOtp('');
    setServiceError(0);
    setForbidden(false);
  }

  return (
    <LoginContext.Provider value={{
      cleanUp,
      universe,
      setUniverse,
      value,
      setValue,
      otpAvailable,
      setOtpAvailable,
      otp,
      setOtp,
      serviceError,
      setServiceError,
      request,
      setRequest,
      forbidden,
      setForbidden,
      kind,
      setKind,
      isLogged,
      setIsLogged,
      receiver,
      setReceiver,
    }}>
      { children }
    </LoginContext.Provider>
  )
};

export {
  LoginProvider as default,
  LoginContext,
};
