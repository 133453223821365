import Request from "../common/Request";

const LM_BASE_URL = `${process.env.LM_BASE_URL}`;
const GET_OTP_URL = LM_BASE_URL + `${process.env.GET_OTP_URL}`;
const SET_OTP_URL = LM_BASE_URL + `${process.env.SET_OTP_URL}`;
const REFRESH_URL = LM_BASE_URL + `${process.env.REFRESH_URL}`;

//////////////////
// GET REQUESTS //
//////////////////
const commonGetOTP = (data: object) =>
  Request(GET_OTP_URL, {
    data,
    method: "POST",
  });

const getOTPByNumber = (phone: string) =>
  commonGetOTP({
    phoneNumber: `56${ phone }`,
    "@type": "OtpInitRequestByPhoneNumber",
  });

const getOTPByEmail = (email: string) =>
  commonGetOTP({
    email,
    "@type": "OtpInitRequestByEmail",
  });

const getOTPByRut = (rut: string) =>
  commonGetOTP({
    rut,
    "@type": "OtpInitRequestByRut",
  });

////////////////////
// LOGIN REQUESTS //
////////////////////
const commonPostOTP = (data: object) =>
  Request(SET_OTP_URL, {
    data,
    method: "POST",
  });

const loginOTPByNumber = ({ phone, otp }: { phone: string, otp: string }) =>
  commonPostOTP({
    otp,
    phoneNumber: `56${ phone }`,
    "@type": "OtpLoginRequestByPhoneNumber",
  });

const loginOTPByEmail = ({ email, otp }: { email: string, otp: string }) =>
  commonPostOTP({
    email, otp, "@type": "OtpLoginRequestByEmail",
  });

const loginOTPByRut = ({ rut, otp }: { rut: string, otp: string }) =>
  commonPostOTP({
    rut, otp, "@type": "OtpLoginRequestByRut",
  });

/////////////
// REFRESH //
/////////////
const refreshToken = (token: string) =>
  Request(REFRESH_URL, {
    data: {
      refreshToken: token,
    },
    method: "POST",
  });


export {
  getOTPByNumber,
  getOTPByEmail,
  getOTPByRut,
  loginOTPByNumber,
  loginOTPByEmail,
  loginOTPByRut,
  refreshToken,
};
