import React, { FormEvent, useContext, useEffect, useState } from 'react';
import FormLayoutStyled from './styles/FormLayoutStyled';
import { RutInput } from '@womcorp/wom-miwom-ui-kit/dist/components/Input';
import Button from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import { LoaderBlock } from '@womcorp/wom-miwom-ui-kit/dist/components/Loader';
import { LoginContext } from './context/LoginProvider';
import useGetOtp from './hooks/useGetOtp';
import FiberPanel from './panel/FiberPanel';
import GTMStepOne from './helpers-gtm/GTMStepOne';

const ByFiber = () => {
  const { value, setValue, setKind } = useContext(LoginContext);
  const [invalid, setInvalid] = useState(false);
  const isDisabled = value.length < 11;
  const [getOtp, loading, statusResponse] = useGetOtp();
  const [openPanel, setOpenPanel] = useState(false);

  const handleGetOtp = () => {
    GTMStepOne.tagFiberEvent('Solicitar Código');
    getOtp();
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleGetOtp();
  }

  useEffect(() => {
    setKind('rut');
  }, []);

  useEffect(() => {
    if (statusResponse === 404) setOpenPanel(true);
  }, [statusResponse])

  if (loading) return <LoaderBlock />

  return (
    <>
      <FormLayoutStyled onSubmit={handleSubmit}>
        <RutInput
          value={value}
          error={invalid}
          onChange={setValue}
          setInvalid={setInvalid}
          label="Rut"
        />
        <Button
          disabled={isDisabled || invalid}
          onClick={handleGetOtp}
          children="Solicitar Código"
        />
      </FormLayoutStyled>
      <FiberPanel open={openPanel} setOpen={setOpenPanel} />
    </>
  );
};

export default ByFiber;
