import React, { useContext, useEffect } from 'react';
import { AlertaCirculo } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/pictures';
import Button, {
  ButtonLink,
} from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import Typography from '@womcorp/wom-miwom-ui-kit/dist/components/Typography';
import Panel from '@womcorp/wom-miwom-ui-kit/dist/components/Panel';
import PanelStyled from '../styles/PanelStyled';
import { LoginContext } from '../context/LoginProvider';
import TagManager from '../../../common/TagManager';
import { getEncodedUserID } from '../../../helpers/userID';

const GenericErrorPanel = () => {
  const { kind, request, serviceError, setServiceError, universe, value } =
    useContext(LoginContext);
  const onClose = () => setServiceError(0);
  const handleTryAgain = () => {
    if (request) request();
    onClose();
  };

  const error = (() => {
    if (
      (universe === 'tv' && serviceError === 400) ||
      (universe === 'phone' && serviceError === 404) ||
      (universe === 'fiber' && serviceError === 404)
    )
      return false;
    else if (serviceError) return true;
    return false;
  })();

  const markTagManagerError = () =>
    TagManager.VirtualPage({
      page: '/sso/errorservicio',
      pagename: 'SSO_ERROR_SERVICIO',
      userID: getEncodedUserID(kind, value),
    });

  useEffect(() => {
    if (error) markTagManagerError();
  }, [error]);

  return (
    <Panel
      open={error}
      title="No es posible continuar"
      onClose={onClose}
      maxWidth={360}
    >
      <PanelStyled>
        <AlertaCirculo />
        <Typography type="Subtitulo20" weight="bold">
          No disponible
        </Typography>
        <Typography type="Body">
          Nuestro sistema ha fallado.
          <br />
          Reintenta cargar tu información en los próximos minutos
        </Typography>
        <Button onClick={handleTryAgain}>Reintentar</Button>
        <ButtonLink colorName="bubblegum" onClick={onClose}>
          Cancelar
        </ButtonLink>
      </PanelStyled>
    </Panel>
  );
};

export default GenericErrorPanel;
