import React, { useContext, useState } from 'react';
import {
  loginOTPByNumber,
  loginOTPByRut,
  loginOTPByEmail,
} from '../../../services/OTPServices';
import { navigate } from '../../../common/Utils';
import { LoginContext } from '../context/LoginProvider';
import { SetOTPStorage } from '../../../common/Storage';
import {
  CleanRut
} from '@womcorp/wom-miwom-ui-kit/dist/components/Input/@helpers/Parser';
import GTMStepTwo from '../helpers-gtm/GTMStepTwo';
import GTMOtpError from '../helpers-gtm/GTMError';
import { getEncodedUserID } from '../../../helpers/userID';

const useSetOtp: () => [((x: string) => void), boolean, boolean] = () => {
  const { value, universe, setOtp, kind, setForbidden, setIsLogged } = useContext(LoginContext);
  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = (response: { data: { access_token: string; refresh_token: string; } }) => {
    SetOTPStorage(response.data);
    setIsLogged(true);
  }

  const handleSetOtp = (OTPFromComponent: string) => {
    setLoading(true);
    setOtp(OTPFromComponent);
    setForbidden(false);

    // callback "Iniciar Sesión" button OTP UI KIT component
    if (universe === 'phone') GTMStepTwo.tagMobileEvent('Iniciar sesión');
    else if (universe === 'bam') GTMStepTwo.tagBamEvent('Iniciar sesión');
    else if (universe === 'fiber') GTMStepTwo.tagFiberEvent('Iniciar sesión');
    else if (universe === 'tv') GTMStepTwo.tagWomTVEvent('Iniciar sesión');

    const FetchRequest = ({
      phone: () => loginOTPByNumber({
        otp: OTPFromComponent,
        phone: value.replace(/ /g, ''),
      }),
      rut: () => loginOTPByRut({
        otp: OTPFromComponent,
        rut: CleanRut(value)
      }),
      email: () => loginOTPByEmail({
        otp: OTPFromComponent,
        email: value,
      })
    }[kind]);

    const handleError = () => {
      GTMOtpError.tagManagerVirtualPageOtpError(universe, getEncodedUserID(kind, value));
      setOtpError(true);
    }

    FetchRequest()
      .then(response => {
        if (response.status === 200)
          handleLogin(response)
        else if (response.status === 403)
          setForbidden(true);
        else if (response.status >= 400)
          handleError();
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return [handleSetOtp, loading, otpError];
}

export default useSetOtp;
