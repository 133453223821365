import React, { Dispatch, SetStateAction } from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SelectArrow, BamIcon, CellphoneIcon, FiberIcon, TvIcon, ToggleIcon } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/forms';
import SSOSelectStyled, { ItemStyled } from './styles/SSOSelectStyled';

/**
 * The value managed by the parent
 */
export type SSOValueType = "phone" | "bam" | "fiber" | "tv";
export type SSOEventType = (event: SelectChangeEvent<SSOValueType>) => void;
export type StateType = Dispatch<SetStateAction<SSOValueType>>;

export interface SSOSelectInterface {
  value: SSOValueType;
  /**
  * The Handle Change callback for parent control
  */
  onChange: SSOEventType;
  /**
   * Available channels controlled by feature flags
   */
  availableChannels: {
    mobile?: boolean,
    bam?: boolean,
    fiber?: boolean,
    tv?: boolean,
  }
}

/**
 * SSO Select: first select component in UI KIT, only works for SSO flux
 * TODO: create a polymorphic component
*/
const SSOSelect = ({ value, onChange, availableChannels = {} }: SSOSelectInterface) => (
  <SSOSelectStyled>
    <ToggleIcon />
    <FormControl
      fullWidth={true}
      sx={{
        '.MuiSelect-select': {
          padding: '7px 14px',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: '4px',
          svg: {
            height: '22px',
          },
        }
      }}>
      <Select
        value={value as SSOValueType}
        onChange={onChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={SelectArrow}
        MenuProps={{
          sx: {
            '.MuiPaper-root': { borderRadius: '8px' },
          },
          MenuListProps: {
            disablePadding: true,
          }
        }}
        sx={{
          borderRadius: '20px',
        }}
      >
        {
          availableChannels.mobile && (
            <ItemStyled value="phone">
              <CellphoneIcon />
              <div>Telefonía Móvil</div>
            </ItemStyled>
          )
        }
        {
          availableChannels.bam && (
            <ItemStyled value="bam">
              <BamIcon />
              <div>Internet Móvil</div>
            </ItemStyled>
          )
        }
        {
          availableChannels.fiber && (
            <ItemStyled value="fiber">
              <FiberIcon />
              <div>Internet Fibra</div>
            </ItemStyled>
          )
        }
        {
          availableChannels.tv && (
            <ItemStyled value="tv">
              <TvIcon />
              <div>WOM TV</div>
            </ItemStyled>
          )
        }
      </Select>
    </FormControl>
  </SSOSelectStyled>
);

export default SSOSelect;
