import { Buffer } from 'buffer';

const encodeBase64 = (data: string) => {
    return Buffer.from(data).toString('base64');
}

const decodeBase64 = (data: string) => {
    return Buffer.from(data, 'base64').toString('ascii');
}

export { encodeBase64, decodeBase64 }