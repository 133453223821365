import TagManager from '../common/TagManager';
import { navigate } from '../common/Utils';
import { UniverseType } from '../components/Login/@types';

const UniverseSelector: (universe: UniverseType) => void = (universe) => {
  const mappedEvent = {
    phone: 'Telefonía Móvil',
    bam: 'Internet Móvil',
    fiber: 'Internet Fibra',
    tv: 'WOM TV',
  }[universe];

  if (mappedEvent) {
    TagManager.InteractiveEvent({
      'evento-interactivo-categoria': 'Login',
      'evento-interactivo-accion': 'Selección Servicio',
      'evento-interactivo-etiqueta': mappedEvent,
    });

    if (universe === 'phone') navigate('/login/movil')
    else if (universe === 'fiber') navigate('/login/womfibra')
    else if (universe === 'bam') navigate('/login/bam')
    else if (universe === 'tv') navigate('/login/womtv')
  }
}

export default UniverseSelector;
