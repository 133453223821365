import React, { useContext, useEffect } from 'react';
import { ServiceChat } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/pictures';
import Button, { ButtonLink } from '@womcorp/wom-miwom-ui-kit/dist/components/Button';
import Typography from '@womcorp/wom-miwom-ui-kit/dist/components/Typography';
import Panel from '@womcorp/wom-miwom-ui-kit/dist/components/Panel';
import PanelStyled from '../styles/PanelStyled';
import { PanelType } from '../@types';
import { LoginContext } from '../context/LoginProvider';
import { GridLayout, FlexLayout} from '@womcorp/wom-miwom-ui-kit/dist/components/Layout';
import { WhatsappIcon } from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/forms';
import GTMError from '../helpers-gtm/GTMError';
import GTMStepOne from '../helpers-gtm/GTMStepOne';

const TvPanel: PanelType = ({ open, setOpen}) => {
  const { setIsLogged } = useContext(LoginContext)
  const onClose = () => setOpen(false);
  const onCloseByCTA = () => {
    GTMStepOne.panelWomTVEvent('Cancelar');
    setOpen(false);
  };

  const handleWhatsapp = () => {
    GTMStepOne.panelWomTVEvent('Whatsapp Ejecutivo');
    setIsLogged(false);
  };

  useEffect(() => {
    if (open)
      GTMError.tagManagerVirtualPageTvPanelError();
  }, [open]);

  return (
    <Panel open={open} title="AYUDA" onClose={onClose} maxWidth={360}>
      <PanelStyled>
        <ServiceChat />
        <Typography type="Subtitulo20" weight="bold">¿No puedes ingresar a tu cuenta?</Typography>
        <Typography type="Body">
            Si necesitas ayuda escríbenos por <b>WhatsApp</b> y uno de nuestros ejecutivos te ayudará a resolver tu problema.
        </Typography>
        <FlexLayout margin="32px 0 8px 0">
        <Button variant='contained' external={{
          href: 'https://wa.me/56937400691',
          target: '_blank',
        }} onClick={handleWhatsapp}>
            
        <GridLayout gap={8} columns="auto 1fr">
            <WhatsappIcon />
            <div>Whatsapp ejecutivo</div>
            </GridLayout>
        </Button>
        </FlexLayout>
        <FlexLayout margin="8px 0 8px 0">
        <ButtonLink  colorName="bubblegum" onClick={onCloseByCTA}>Cancelar</ButtonLink>
        </FlexLayout>
      </PanelStyled>
    </Panel>
  );
}

export default TvPanel;
