import React from 'react';
import LoginProvider from '../components/Login/context/LoginProvider';
import RainbowLaneStyled from '../components/Login/styles/RainbowLaneStyled';
import LoginStyled, {
  LogoContainerStyled,
} from '../components/Login/styles/LoginStyled';
import {
  LogoSVG
} from '@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/miscellaneous'
import { LoginPageType } from '../components/Login/@types';
import LoginFlux from '../components/Login';

const Login: LoginPageType = ({ pageContext: { login: universe } }) => {
  return (
    <LoginProvider initialUniverse={universe}>
      <RainbowLaneStyled />
      <LoginStyled>
        <LogoContainerStyled>
          <LogoSVG />
        </LogoContainerStyled>
        <LoginFlux universe={universe}/>
      </LoginStyled>
    </LoginProvider>
  );
}

export default Login;
