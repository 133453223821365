import TagManager from '../../../common/TagManager';
import { UniverseType, KindType } from '../@types';

const tagManagerVirtualPage = (universe: UniverseType, kind?: KindType) => {
  if (universe === 'phone') {
    if (kind === 'phone') {
      TagManager.VirtualPage({
        'page': '/sso/movil/paso1',
        'pagename': 'SSO_LOGIN_MOVIL_PASO_1',
      });
    } else if (kind === 'rut') {
      TagManager.VirtualPage({
        'page': '/sso/movil/paso1',
        'pagename': 'SSO_LOGIN_MOVIL_PASO_1_RUT',
      });
    }
  } else if (universe === 'bam') {
    TagManager.VirtualPage({
      page: '/sso/bam/paso1',
      pagename: 'SSO_LOGIN_BAM_PASO_1',
    });
  } else if (universe === 'fiber') {
    TagManager.VirtualPage({
      page: '/sso/fibra/paso1',
      pagename: 'SSO_LOGIN_FIBRA_PASO_1',
    });
  } else if (universe === 'tv') {
    TagManager.VirtualPage({
      page: '/sso/womtv/paso1',
      pagename: 'SSO_LOGIN_WOMTV_PASO_1',
    });
  }
}

type TypeTagMobileEvent = 'Solicitar Código' | 'Ingresar con RUT';

const tagMobileEvent = (tag: TypeTagMobileEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria':'Login Movil',
    'evento-interactivo-accion':'Login',
    'evento-interactivo-etiqueta': tag,
  });
}

type TypeTagMobileRutEvent = 'Solicitar Código' | 'Ingresar con Teléfono';

const tagMobileRutEvent = (tag: TypeTagMobileRutEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria':'Login Movil',
    'evento-interactivo-accion':'Login',
    'evento-interactivo-etiqueta': tag,
  });
}

type TypeTagFiberEvent = 'Solicitar Código';

const tagFiberEvent = (tag: TypeTagFiberEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria':'Login Fibra',
    'evento-interactivo-accion':'RUT',
    'evento-interactivo-etiqueta': tag,
  });
}

type TypeTagBamEvent = 'Solicitar Código' | 'Tu Bam Prepago';

const tagBamEvent = (tag: TypeTagBamEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria':'Login BAM',
    'evento-interactivo-accion':'RUT',
    'evento-interactivo-etiqueta': tag,
  });
}

type TypeTagWomTvNewLoginEvent =
  'Solicitar Código' | 'Olvidé mi Email' | 'Comenzar Registro';

const tagWomTVEvent = (tag: TypeTagWomTvNewLoginEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria':'Login WOMTV',
    'evento-interactivo-accion':'Email',
    'evento-interactivo-etiqueta': tag,
  });
};

type TypePanelWomTVEvent =  "Whatsapp Ejecutivo" | "Cancelar";

const panelWomTVEvent = (tag: TypePanelWomTVEvent) => {
  TagManager.InteractiveEvent({
    'evento-interactivo-categoria': 'Login WOMTV',
    'evento-interactivo-accion': 'Ayuda',
    'evento-interactivo-etiqueta': tag,
  })
};

export default {
  tagManagerVirtualPage,
  tagMobileEvent,
  tagMobileRutEvent,
  tagBamEvent,
  tagFiberEvent,
  tagWomTVEvent,
  panelWomTVEvent,
}
