import styled from '@emotion/styled';

const LoginStyled = styled.div`
  padding: 32px 16px;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
`;

const LogoContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  transform: scale(0.8);
  @media screen and (min-width: 768px) {
    transform: scale(1);
  }
`;

const SelectionWrapperStyled = styled.div`
  max-width: 360px;
  margin: auto;
  text-align: center;
  padding-top: 32px;
  & > div {
    :nth-of-type(1) {
      font-weight: 700;
      padding-bottom: 16px;
    }
    :nth-of-type(2) {
      text-align: left;
      margin: auto;
      max-width: 240px;
    }
  }

  & > form,
  & > div:nth-of-type(3) {
    margin-top: 40px;
  }
`;

const LoaderContainerStyled = styled.div`
  text-align: center;
`;

export {
  LoginStyled as default,
  LogoContainerStyled,
  SelectionWrapperStyled,
  LoaderContainerStyled,
};
